<template>
  <div class="about">
    <Nav />

    <PageHero :heroSrc="heroSrc" :title="title" :sub="sub" />
    <!-- <SubPageContent :sections="sections" pageName="about" /> -->

    <section class="cols dual">
      <div class="image-col">
        <p>
          in3D is an Israeli based company with worldwide aspirations. We create
          virtual environments for different clients from all around the world.
          We specialize in customize products and services. Our expertise is to
          create 3D / XR software and applications to overcome any challenge, to
          inspire, improve and develop, taking together a big leap forward
        </p>
        <img src="img/sub-pages/xr/0.jpg" alt="pageName missing" />
      </div>
      <div class="image-col reverse">
        <div class="text-wrap">
          <h2>WE ARE 3D PROFESSIONALS AND THE ONLY ONE STOP SHOP FOR:</h2>
          <ul>
            <li>3D software, applications and web development</li>
            <li>3D scanning and photographing</li>
            <li>Simulating and animating</li>
          </ul>
        </div>
        <img src="img/sub-pages/xr/0.jpg" alt="pageName missing" />
      </div>
    </section>
    <section class="cols single">
      <div class="image-col">
        <div class="text-wrap">
          <h2>
            WE ARE NOT A START-UP WE RATHER START WORKING WITH YOU OUR DOMIANS:
          </h2>
          <ul>
            <li>Training & simulations</li>
            <li>Engineering & maintenance</li>
            <li>Marketing content</li>
            <li>Customized solutions</li>
            <li>Production / 3D animation</li>
          </ul>
          <h2>OUR PRODUCTS AND SERVICES ARE CUSTOMIZED FOR YOUR NEEDS:</h2>
          <ul>
            <li>3D digital twin – modeled/scanned</li>
            <li>Virtual assistant – both live and holographic assist</li>
            <li>Holographic assistant – XR development</li>
          </ul>
          <h2>SERVICES:</h2>
          <ul>
            <li>3D softwares, applications and web development</li>
            <li>Drone services</li>
            <li>3D scanning</li>
            <li>3D modeling and rendering</li>
            <li>Production – 3D animation</li>
          </ul>
        </div>
      </div>
      <div class="image-col">
        <img src="img/sub-pages/xr/0.jpg" alt="pageName missing" />
      </div>
    </section>
    <section class="cols single">
      <div class="image-col">
        <img src="img/sub-pages/xr/0.jpg" alt="pageName missing" />
      </div>
      <div class="image-col">
        <div class="text-wrap">
          <p>
            in3D WAS ESHABLISHED ON 2016 By Mr. Nathanael Reicher and Mr. Ran
            Haikin, both are Israeli Naval academy graduates with years of
            experience in the most elite and advanced technology available. in3D
            has hired the best minds in the Israeli high-tech community in order
            to produce a wide variety of 3D technological implementations to the
            global market. in3D is an ISO9001 and ISO27001 certified and we are
            working with the most demanding and qualified organizations so you
            can feel safe with our ability to fulfill our duty and excel.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import PageHero from "@/components/PageHero.vue";
import SubPageContent from "@/components/SubPageContent.vue";

export default {
  name: "About",
  components: { PageHero, SubPageContent, Nav },
  data: () => {
    return {
      heroSrc: "img/about-bk.webp",
      title:
        "WE ARE on a mission to evolve \nCreate and inspire \n3D isn't a technology \nIt’s a way of thinking \nOur goal is to gather all senses \nCapturing is no longer an action of the eyes alone",

      sections: [
        {
          p:
            "CONTROL YOUR DIGITAL TWIN: in3D develops 3D software/applications or 3D websites to manage and control your digital twin. 3D models utilities' potential is never-ending: measuring, styling, instructing, engineering, planning, tracking, marketing and the list goes on, but in order to fulfill and control that potential you need a high quality 3D platform that will enable you to take your business operations to the next level.",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
section {
  height: 100vh;
  text-align: left;
}
.cols {
  display: flex;
  // width: 100%;
  // flex: 0 1 auto;
  .image-col {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    padding-left: 40px;
    &:last-of-type {
      margin-right: 40px;
    }
    // margin-right: 50px;
    &.reverse {
      flex-direction: column-reverse;
    }
    h2 {
      margin: 0;
    }
    p {
      // line-break: auto;
    }
    img {
      width: 400px;
      // height: 40vh;
      // width: 30vw;
    }
  }
}
</style>